<template>
  <v-container>
    <h1>
      <span>Редактирование информации о проекте</span>
    </h1>

    <v-form ref="form" v-if="apiLoaded">
      <section class="form-wrap">
        <v-row>
          <v-col cols="12"><h2>Общая информация</h2></v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select
              label="Год проекта"
              dense
              outlined
              v-model="projectCommonInfo.year"
              :items="[2021, 2022, 2023, 2024]"
              :rules="[(v) => v != null || 'Поле не должно быть пустым']"
              :disabled="yearDisabled"
            ></v-select>
          </v-col>
        </v-row>

        <!--        <v-row>-->
        <!--          <v-col cols="12">-->
        <!--            <v-text-field-->
        <!--              label="Номер проекта"-->
        <!--              dense-->
        <!--              outlined-->
        <!--              v-model="projectCommonInfo.number"-->
        <!--              :rules="[-->
        <!--                (v) =>-->
        <!--                  (v != null && v.length > 0) || 'Поле не должно быть пустым',-->
        <!--                (v) =>-->
        <!--                  (v && v.length <= 32) || 'Максимальное число символов - 32',-->
        <!--              ]"-->
        <!--            ></v-text-field>-->
        <!--          </v-col>-->
        <!--        </v-row>-->

        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Название проекта"
              dense
              outlined
              v-model="projectCommonInfo.name"
              :rules="[
                (v) =>
                  (v != null && v.length > 0) || 'Поле не должно быть пустым',
                (v) =>
                  !v ||
                  v.length <= 2048 ||
                  'Максимальное число символов - 2048',
              ]"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select
              label="Статус"
              dense
              outlined
              :items="projectStatusDict"
              item-text="title"
              item-value="code"
              v-model="projectCommonInfo.status"
              :rules="[(v) => v != null || 'Поле не должно быть пустым']"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <date-field
              label="Дата начала"
              :value="projectCommonInfo.startDate"
              @input="projectCommonInfo.startDate = $event"
              min-date="2000-01-01"
              max-date="2099-12-31"
            ></date-field>
          </v-col>
          <v-col cols="6">
            <date-field
              label="Дата окончания"
              :value="projectCommonInfo.endDate"
              @input="projectCommonInfo.endDate = $event"
              min-date="2000-01-01"
              max-date="2099-12-31"
            ></date-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-textarea
              outlined
              label="Описание проекта"
              v-model="projectCommonInfo.description"
              :rules="[
                (v) =>
                  !v ||
                  v.length <= 12000 ||
                  'Максимальное число символов - 12000',
              ]"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select
              label="Руководитель проекта"
              :items="offerExperts"
              item-text="fullName"
              item-value="id"
              v-model="projectCommonInfo.offerExpert"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select
              label="Рынки"
              :items="supMarketDict"
              item-text="title"
              item-value="code"
              v-model="projectCommonInfo.markets"
              multiple
              chips
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-checkbox
            label="Без договора"
            v-model="projectCommonInfo.noContract"
          ></v-checkbox>
        </v-row>
        <br />
      </section>

      <!-- вставить миниммальное создание проводки! -->

      <section v-if="!this.projectId">
        <h2>Данные проводки</h2>
        <br />
        <v-row>
          <v-col cols="12">
            <v-select
              label="Организация*"
              :items="ourOrgs"
              item-text="shortName"
              item-value="id"
              return-object
              v-model="wData.organization"
              dense
              outlined
              clearable
              @change="
                if (wData.organization) {
                  if (wData.organization.accounts != null) {
                    accountsDict = [...wData.organization.accounts];
                  } else {
                    accountsDict = [
                      'Необходимо заполнить номер счета организации.',
                    ];
                  }
                } else {
                  accountsDict = [];
                  wData.accountNumber = null;
                }
              "
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select
              :items="accountsDict"
              v-model="wData.accountNumber"
              label="Номер счета"
              dense
              outlined
              :disabled="!wData.organization"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select
              label="Ответственный*"
              :items="experts"
              item-text="fullName"
              item-value="id"
              return-object
              v-model="wData.expert"
              dense
              outlined
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <h4>тип проводки*</h4>
            <v-radio-group
              row
              v-model="wData.wiringType"
              required
              :rules="rules.select"
            >
              <v-radio :value="1" label="Планируемая"></v-radio>
              <v-radio :value="2" label="Фактическая"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row style="margin-bottom: 40px">
          <v-col cols="12">
            <h4>Приход/Расход*</h4>
            <v-radio-group
              row
              v-model="wData.wiringTypeDir"
              @change="
                if (wData.project && wData.wiringTypeDir) {
                  funds = fundsDict[wData.wiringTypeDir].filter(
                    (item) => item.project == true
                  );
                } else {
                  funds = fundsDict[wData.wiringTypeDir].filter(
                    (item) => item.project == false
                  );
                }
                wData.funds = ' ';
                wData.article = ' ';
                wData.paymentMethod = ' ';
                articles = [];
                paymentMethods = [];
              "
              :rules="rules.select"
              required
            >
              <v-radio value="expences" label="Расход"></v-radio>
              <v-radio value="income" label="Доход"></v-radio>
              <!-- <v-radio
                value="internalRedistribution"
                label="Перераспределение внутреннее"
                :disabled="!!wData.project"
              ></v-radio> -->
              <v-radio value="securityDeposit" label="Обеспечение"></v-radio>
              <!-- <v-radio
                value="externalRedistribution"
                label="Перераспределение внешнее"
                :disabled="!!wData.project"
              ></v-radio>
              <v-radio
                value="reserve"
                label="Резерв"
                :disabled="!!wData.project"
              ></v-radio>
              <v-radio
                value="nonfund"
                label="Нефондовые"
                :disabled="!!wData.project"
              ></v-radio> -->
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-select
              label="Фонд*"
              :items="funds"
              item-text="fund"
              item-value="id"
              return-object
              v-model="wData.funds"
              dense
              outlined
              required
              :rules="rules.select"
              @focus="
                if (wData.funds == ' ') {
                  wData.funds = null;
                }
              "
              @change="
                articles = articlesDict[wData.funds.id];
                paymentMethods = [];
                wData.article = ' ';
                wData.paymentMethod = ' ';
                paymentMethods = [];
              "
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              label="Статья*"
              :items="articles"
              item-text="article"
              item-value="id"
              return-object
              v-model="wData.article"
              dense
              outlined
              required
              :rules="rules.select"
              @focus="
                if (wData.article == ' ') {
                  wData.article = null;
                }
              "
              @change="
                paymentMethods = [
                  { id: 1, text: 'Наличный', value: wData.article.cash },
                  {
                    id: 2,
                    text: 'Безналичный',
                    value: wData.article.cashless,
                  },
                ].filter((el) => el.value == true);
                wData.paymentMethod = ' ';
              "
            ></v-select>
          </v-col>
        </v-row>
        <v-row style="margin-bottom: 40px">
          <v-col cols="12">
            <v-select
              label="Способ оплаты*"
              :items="paymentMethods"
              item-text="text"
              item-value="id"
              return-object
              v-model="wData.paymentMethod"
              dense
              outlined
              required
              :rules="rules.select"
              @focus="
                if (wData.paymentMethod == ' ') {
                  wData.paymentMethod = null;
                }
              "
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <date-field
              :label="
                wData.wiringType != 1
                  ? 'Дата оплаты*'
                  : 'Дата оплаты (планируемая)*'
              "
              :value="wData.paymentDate"
              @input="wData.paymentDate = $event"
              v-bind:min-date="
                wData.wiringType == 1 ? getTodayDateString() : '2000-01-01'
              "
              max-date="2029-12-31"
            ></date-field>
          </v-col>
        </v-row>

        <register-select-field
          field-title="Договор"
          dialog-title="Выбор договора"
          :reg-config="customerContractsRegisterConfig"
          page-size="10"
          default-tab="0"
          :text-function="(item) => item.theme || ''"
          text-search-attribute="theme"
          :value-object="wData.contractId"
          @select="
            wData.contractId = $event;
            $forceUpdate();
          "
          @clear="
            wData.contractId = null;
            $forceUpdate();
          "
          not-null="true"
        >
        </register-select-field>

        <register-select-field
          field-title="Контрагент"
          dialog-title="Выбор контрагент"
          :reg-config="counterpartiesRegisterConfig"
          page-size="10"
          default-tab="0"
          :text-function="(item) => item.fullName || ''"
          text-search-attribute="fullName"
          :value-object="wData.contractor"
          @select="
            wData.contractor = $event;
            $forceUpdate();
          "
          @clear="
            wData.contractor = null;
            $forceUpdate();
          "
          not-null="true"
        >
        </register-select-field>

        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Сумма (руб.)*"
              v-model="wData.sum"
              dense
              outlined
              :rules="[
                (v) =>
                  v == null ||
                  v.length === 0 ||
                  !!normalizeNumber(v, 0, 1e13, 2) ||
                  'Введите корректную сумму',
              ]"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-textarea
              outlined
              label="Примечание"
              v-model="wData.comment"
            ></v-textarea>
          </v-col>
        </v-row>
      </section>
      <!-- Минимальная проводка -->
      <div style="margin-top: 50px">
        <v-btn outlined @click="cancel()">Отменить</v-btn>
        <v-btn @click="submit">Сохранить</v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import { projectStatusDict, supMarketDict } from '@/modules/NSI';
import DateField from '@/components/elements/DateField';
import { loadDataToObject } from '@/modules/CommonUtils';
import { deleteNullFields } from '@/modules/CommonUtils';
import { wPost } from '@/modules/Wirings';
import api from '@/modules/api';
import { projectKeyHolder } from '@/modules/Projects';
import {
  articlesDict,
  fundsDict,
  editFormValidator,
} from '@/components/register/WireDict';
import {
  // projectsRegisterConfig,
  customerContractsRegisterConfig,
  supplierContractsRegisterConfig,
  customerCounterpartiesRegisterConfig,
  supplierCounterpartiesRegisterConfig,
} from '@/components/register/RegisterConfigs';
import { ourOrgsAccountsDict } from '@/modules/NSI';
import { normalizeNumber } from '@/modules/CommonUtils';
import RegisterSelectField from '@/components/register/RegisterSelectField';

export default {
  name: 'ContractEditComponent',
  components: { RegisterSelectField, DateField },
  data() {
    return {
      projectCommonInfo: { year: new Date().getFullYear() },
      projectStatusDict: projectStatusDict,
      supMarketDict: supMarketDict,
      offerExperts: undefined,
      apiLoaded: false,
      apiError: false,
      yearDisabled: false,
      normalizeNumber: normalizeNumber,

      articlesDict: articlesDict,
      fundsDict: fundsDict,
      editFormValidator: editFormValidator,
      accountsDict: ourOrgsAccountsDict,
      experts: [],
      rules: {
        select: [(v) => !!v || 'Обязательное поле.'],
      },
      articles: [],
      funds: [],
      paymentMethods: [],
      customerCounterpartiesRegisterConfig:
        customerCounterpartiesRegisterConfig,
      supplierCounterpartiesRegisterConfig:
        supplierCounterpartiesRegisterConfig,
      counterpartiesRegisterConfig: {
        tabs: [
          {
            title: 'Заказчики',
            config: customerCounterpartiesRegisterConfig,
          },
          {
            title: 'Подрядчики',
            config: supplierCounterpartiesRegisterConfig,
          },
        ],
      },
      customerContractsRegisterConfig: {
        tabs: [
          { title: 'С заказчиками', config: customerContractsRegisterConfig },
          { title: 'С поставщиками', config: supplierContractsRegisterConfig },
        ],
      },

      wData: {
        accountNumber: '',
        article: '',
        budgetYear: new Date().getFullYear(),
        comment: '',
        contractId: '',
        contractor: '',
        expert: '',
        organization: null,
        paymentDate: '',
        paymentMethod: 1,
        project: this.projectCommonInfo,
        funds: '',
        sum: '',
        wiringType: 1,
        toConsole: () => {
          console.log(this.wData);
        },
        postData: async () => {
          const status = await this.wPost(new Date().getFullYear(), this.wData);
          if (status == 200) {
            return status;
          }
        },
        onError: () => {
          this.errorMessage = 'Не все обязательные поля заполнены';
        },
      },
    };
  },
  methods: {
    loadData: loadDataToObject,
    wPost: wPost,
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (!this.editFormValidator(this.wData)) {
        return;
      }
      this.projectCommonInfo = deleteNullFields(this.projectCommonInfo, true);
      const encrypted = await projectKeyHolder.encrypt(
        this.projectCommonInfo,
        this.projectId ? this.encrypted.addit : null
      );
      encrypted.year = this.projectCommonInfo.year;
      if (this.projectId) encrypted.id = this.projectId;

      let req;
      if (this.projectId)
        req = await api.putJson('/supmain/projects/', encrypted);
      else req = await api.postJson('/supmain/projects/', encrypted);
      console.log(req);
      if (req.ok) {
        this.projectCommonInfo['id'] = req.payload.id;
        this.wData.project = this.projectCommonInfo;
        // получить id и создать проводку
        let res = await this.wData.postData();
        if (res == 200) {
          this.$router.push({ path: '/project/' + req.payload.id });
        } else {
          console.log('Ошибка в создании проводки.');
        }
      } else {
        alert('Что-то пошло не так');
      }
    },

    cancel() {
      if (this.projectId != null) {
        this.$router.push({ path: '/project/' + this.projectId });
      } else {
        this.$router.push({ path: '/projects/' });
      }
    },

    getTodayDateString: () => {
      return `${new Date().getFullYear()}-${(
        '0' +
        (new Date().getMonth() + 1)
      ).slice(-2)}-${('0' + new Date().getDate()).slice(-2)}`;
    },
  },
  async beforeMount() {
    if (!(await projectKeyHolder.checkOrRequestKey())) return;
    await Promise.all([
      this.loadData(
        '/supmain/experts?page=0&size=100&sort=id,asc&search=isRoleOffer:true',
        'experts',
        true
      ),
      this.loadData(
        '/supmain/experts?page=0&size=100&sort=id,asc&search=isRoleOffer:true',
        'offerExperts',
        true
      ),
    ]);
    const getFullName = function (items) {
      return items.map((e) => {
        e.fullName = [e.surname, e.name, e.middleName].join(' ');
        if (e.fullName === '  ') e.fullName = 'Без имени';
        return e;
      });
    };
    this.offerExperts = getFullName(this.offerExperts);

    this.projectId = this.$route.params.id;
    if (this.projectId) {
      await this.loadData(
        '/supmain/projects/' + this.projectId,
        'encrypted',
        false
      );
      this.projectCommonInfo = await projectKeyHolder.decrypt(
        this.encrypted.data,
        this.encrypted.addit
      );
      this.yearDisabled = true;
      console.log(this.projectCommonInfo);
    }

    this.ourOrgs = ourOrgsAccountsDict;
    this.wData.project = this.projectCommonInfo;
    console.log(this.wData);
    console.log(this.getTodayDateString());
    this.apiLoaded = true;
  },
};
</script>
