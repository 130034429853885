var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',[_c('span',[_vm._v("Редактирование информации о проекте")])]),(_vm.apiLoaded)?_c('v-form',{ref:"form"},[_c('section',{staticClass:"form-wrap"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Общая информация")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Год проекта","dense":"","outlined":"","items":[2021, 2022, 2023, 2024],"rules":[function (v) { return v != null || 'Поле не должно быть пустым'; }],"disabled":_vm.yearDisabled},model:{value:(_vm.projectCommonInfo.year),callback:function ($$v) {_vm.$set(_vm.projectCommonInfo, "year", $$v)},expression:"projectCommonInfo.year"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Название проекта","dense":"","outlined":"","rules":[
              function (v) { return (v != null && v.length > 0) || 'Поле не должно быть пустым'; },
              function (v) { return !v ||
                v.length <= 2048 ||
                'Максимальное число символов - 2048'; } ]},model:{value:(_vm.projectCommonInfo.name),callback:function ($$v) {_vm.$set(_vm.projectCommonInfo, "name", $$v)},expression:"projectCommonInfo.name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Статус","dense":"","outlined":"","items":_vm.projectStatusDict,"item-text":"title","item-value":"code","rules":[function (v) { return v != null || 'Поле не должно быть пустым'; }]},model:{value:(_vm.projectCommonInfo.status),callback:function ($$v) {_vm.$set(_vm.projectCommonInfo, "status", $$v)},expression:"projectCommonInfo.status"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('date-field',{attrs:{"label":"Дата начала","value":_vm.projectCommonInfo.startDate,"min-date":"2000-01-01","max-date":"2099-12-31"},on:{"input":function($event){_vm.projectCommonInfo.startDate = $event}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('date-field',{attrs:{"label":"Дата окончания","value":_vm.projectCommonInfo.endDate,"min-date":"2000-01-01","max-date":"2099-12-31"},on:{"input":function($event){_vm.projectCommonInfo.endDate = $event}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":"Описание проекта","rules":[
              function (v) { return !v ||
                v.length <= 12000 ||
                'Максимальное число символов - 12000'; } ]},model:{value:(_vm.projectCommonInfo.description),callback:function ($$v) {_vm.$set(_vm.projectCommonInfo, "description", $$v)},expression:"projectCommonInfo.description"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Руководитель проекта","items":_vm.offerExperts,"item-text":"fullName","item-value":"id","outlined":"","dense":""},model:{value:(_vm.projectCommonInfo.offerExpert),callback:function ($$v) {_vm.$set(_vm.projectCommonInfo, "offerExpert", $$v)},expression:"projectCommonInfo.offerExpert"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Рынки","items":_vm.supMarketDict,"item-text":"title","item-value":"code","multiple":"","chips":"","outlined":"","dense":""},model:{value:(_vm.projectCommonInfo.markets),callback:function ($$v) {_vm.$set(_vm.projectCommonInfo, "markets", $$v)},expression:"projectCommonInfo.markets"}})],1)],1),_c('v-row',[_c('v-checkbox',{attrs:{"label":"Без договора"},model:{value:(_vm.projectCommonInfo.noContract),callback:function ($$v) {_vm.$set(_vm.projectCommonInfo, "noContract", $$v)},expression:"projectCommonInfo.noContract"}})],1),_c('br')],1),(!this.projectId)?_c('section',[_c('h2',[_vm._v("Данные проводки")]),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Организация*","items":_vm.ourOrgs,"item-text":"shortName","item-value":"id","return-object":"","dense":"","outlined":"","clearable":""},on:{"change":function($event){if (_vm.wData.organization) {
                if (_vm.wData.organization.accounts != null) {
                  _vm.accountsDict = [].concat( _vm.wData.organization.accounts );
                } else {
                  _vm.accountsDict = [
                    'Необходимо заполнить номер счета организации.' ];
                }
              } else {
                _vm.accountsDict = [];
                _vm.wData.accountNumber = null;
              }}},model:{value:(_vm.wData.organization),callback:function ($$v) {_vm.$set(_vm.wData, "organization", $$v)},expression:"wData.organization"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.accountsDict,"label":"Номер счета","dense":"","outlined":"","disabled":!_vm.wData.organization},model:{value:(_vm.wData.accountNumber),callback:function ($$v) {_vm.$set(_vm.wData, "accountNumber", $$v)},expression:"wData.accountNumber"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Ответственный*","items":_vm.experts,"item-text":"fullName","item-value":"id","return-object":"","dense":"","outlined":""},model:{value:(_vm.wData.expert),callback:function ($$v) {_vm.$set(_vm.wData, "expert", $$v)},expression:"wData.expert"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v("тип проводки*")]),_c('v-radio-group',{attrs:{"row":"","required":"","rules":_vm.rules.select},model:{value:(_vm.wData.wiringType),callback:function ($$v) {_vm.$set(_vm.wData, "wiringType", $$v)},expression:"wData.wiringType"}},[_c('v-radio',{attrs:{"value":1,"label":"Планируемая"}}),_c('v-radio',{attrs:{"value":2,"label":"Фактическая"}})],1)],1)],1),_c('v-row',{staticStyle:{"margin-bottom":"40px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v("Приход/Расход*")]),_c('v-radio-group',{attrs:{"row":"","rules":_vm.rules.select,"required":""},on:{"change":function($event){if (_vm.wData.project && _vm.wData.wiringTypeDir) {
                _vm.funds = _vm.fundsDict[_vm.wData.wiringTypeDir].filter(
                  function (item) { return item.project == true; }
                );
              } else {
                _vm.funds = _vm.fundsDict[_vm.wData.wiringTypeDir].filter(
                  function (item) { return item.project == false; }
                );
              }
              _vm.wData.funds = ' ';
              _vm.wData.article = ' ';
              _vm.wData.paymentMethod = ' ';
              _vm.articles = [];
              _vm.paymentMethods = [];}},model:{value:(_vm.wData.wiringTypeDir),callback:function ($$v) {_vm.$set(_vm.wData, "wiringTypeDir", $$v)},expression:"wData.wiringTypeDir"}},[_c('v-radio',{attrs:{"value":"expences","label":"Расход"}}),_c('v-radio',{attrs:{"value":"income","label":"Доход"}}),_c('v-radio',{attrs:{"value":"securityDeposit","label":"Обеспечение"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Фонд*","items":_vm.funds,"item-text":"fund","item-value":"id","return-object":"","dense":"","outlined":"","required":"","rules":_vm.rules.select},on:{"focus":function($event){if (_vm.wData.funds == ' ') {
                _vm.wData.funds = null;
              }},"change":function($event){_vm.articles = _vm.articlesDict[_vm.wData.funds.id];
              _vm.paymentMethods = [];
              _vm.wData.article = ' ';
              _vm.wData.paymentMethod = ' ';
              _vm.paymentMethods = [];}},model:{value:(_vm.wData.funds),callback:function ($$v) {_vm.$set(_vm.wData, "funds", $$v)},expression:"wData.funds"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Статья*","items":_vm.articles,"item-text":"article","item-value":"id","return-object":"","dense":"","outlined":"","required":"","rules":_vm.rules.select},on:{"focus":function($event){if (_vm.wData.article == ' ') {
                _vm.wData.article = null;
              }},"change":function($event){_vm.paymentMethods = [
                { id: 1, text: 'Наличный', value: _vm.wData.article.cash },
                {
                  id: 2,
                  text: 'Безналичный',
                  value: _vm.wData.article.cashless,
                } ].filter(function (el) { return el.value == true; });
              _vm.wData.paymentMethod = ' ';}},model:{value:(_vm.wData.article),callback:function ($$v) {_vm.$set(_vm.wData, "article", $$v)},expression:"wData.article"}})],1)],1),_c('v-row',{staticStyle:{"margin-bottom":"40px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Способ оплаты*","items":_vm.paymentMethods,"item-text":"text","item-value":"id","return-object":"","dense":"","outlined":"","required":"","rules":_vm.rules.select},on:{"focus":function($event){if (_vm.wData.paymentMethod == ' ') {
                _vm.wData.paymentMethod = null;
              }}},model:{value:(_vm.wData.paymentMethod),callback:function ($$v) {_vm.$set(_vm.wData, "paymentMethod", $$v)},expression:"wData.paymentMethod"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('date-field',{attrs:{"label":_vm.wData.wiringType != 1
                ? 'Дата оплаты*'
                : 'Дата оплаты (планируемая)*',"value":_vm.wData.paymentDate,"min-date":_vm.wData.wiringType == 1 ? _vm.getTodayDateString() : '2000-01-01',"max-date":"2029-12-31"},on:{"input":function($event){_vm.wData.paymentDate = $event}}})],1)],1),_c('register-select-field',{attrs:{"field-title":"Договор","dialog-title":"Выбор договора","reg-config":_vm.customerContractsRegisterConfig,"page-size":"10","default-tab":"0","text-function":function (item) { return item.theme || ''; },"text-search-attribute":"theme","value-object":_vm.wData.contractId,"not-null":"true"},on:{"select":function($event){_vm.wData.contractId = $event;
          _vm.$forceUpdate();},"clear":function($event){_vm.wData.contractId = null;
          _vm.$forceUpdate();}}}),_c('register-select-field',{attrs:{"field-title":"Контрагент","dialog-title":"Выбор контрагент","reg-config":_vm.counterpartiesRegisterConfig,"page-size":"10","default-tab":"0","text-function":function (item) { return item.fullName || ''; },"text-search-attribute":"fullName","value-object":_vm.wData.contractor,"not-null":"true"},on:{"select":function($event){_vm.wData.contractor = $event;
          _vm.$forceUpdate();},"clear":function($event){_vm.wData.contractor = null;
          _vm.$forceUpdate();}}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Сумма (руб.)*","dense":"","outlined":"","rules":[
              function (v) { return v == null ||
                v.length === 0 ||
                !!_vm.normalizeNumber(v, 0, 1e13, 2) ||
                'Введите корректную сумму'; } ]},model:{value:(_vm.wData.sum),callback:function ($$v) {_vm.$set(_vm.wData, "sum", $$v)},expression:"wData.sum"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"outlined":"","label":"Примечание"},model:{value:(_vm.wData.comment),callback:function ($$v) {_vm.$set(_vm.wData, "comment", $$v)},expression:"wData.comment"}})],1)],1)],1):_vm._e(),_c('div',{staticStyle:{"margin-top":"50px"}},[_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Отменить")]),_c('v-btn',{on:{"click":_vm.submit}},[_vm._v("Сохранить")])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }